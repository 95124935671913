import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

axios.defaults.baseURL = 'api'; //set base URL (or folder containing the api when on the same server)

type ApiResponseHelloWorld = {
  message: string;
};

export function useApiHelloWorld() {
  return useQuery({
    queryKey: ['hello-world'],
    queryFn: async () => {
      return (await axios.get('/hello-world')).data as ApiResponseHelloWorld;
    }
  });
}
